import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { ICookieService } from 'common/lib/Cookies/types';
import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import useDi from 'common/lib/DependencyInjection/useDi';
import { FetchFailure, FetchSuccess } from 'common/lib/Fetch/types';
import throwOnFailure from 'common/modules/react-query/util/throwOnFailure';
import { config } from 'common/util/configHandler';

import { ApiError } from 'common/modules/profile/hooks/useCandidateDetailQuery';

import userNormalizer from 'common/modules/auth/normalizers/user';

import { ApiItem, User } from 'common/modules/auth/types';
import { PrefetchQuery } from 'common/modules/react-query/types';

import GlobalState from 'common/stores/global';

export const apiEndpoint = 'user';
export const queryKey = ['user'];

export const fetchUser = async (di: DependencyInjection) =>
  userNormalizer(
    throwOnFailure(await di.get('core.fetch').fetch<ApiItem>(apiEndpoint, { preventNavigateToLogin: true })).result
  );

export const shouldFetchUser = (state: GlobalState, cookieService: ICookieService): boolean =>
  !!(!state.featureFlags.includes('onelog-outage') || cookieService.get('disable_onelog_outage')) &&
  !!(cookieService.get('user_authenticated') || config.ENV === 'development');

export const prefetchUser: PrefetchQuery<User> = {
  queryKey,
  queryFn: fetchUser,
};

export const useCurrentUserQuery = (
  options?: Omit<UseQueryOptions<unknown, unknown, User, string[]>, 'initialData' | 'queryFn' | 'queryKey'>
) => {
  const di = useDi();

  return useQuery({
    queryFn: async () => fetchUser(di),
    queryKey,
    enabled: shouldFetchUser(di.get('core.redux').getState(), di.get('core.cookie')),
    ...options,
  });
};

export const useUserMutation = () => {
  const di = useDi();
  const fetchService = di.get('core.fetch');
  const queryClient = useQueryClient();

  const mutationQuery = useMutation<FetchSuccess<ApiItem>, FetchFailure<ApiError>, Partial<User>>({
    mutationFn: async (user) =>
      throwOnFailure(
        await fetchService.fetch<ApiItem, ApiError>(apiEndpoint, {
          method: 'PATCH',
          body: user,
        })
      ),
    onSuccess: (data) => {
      queryClient.setQueryData<User>(queryKey, () => userNormalizer(data.result));
    },
  });

  return mutationQuery;
};
