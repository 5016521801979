import { getPatternStyles, patternFns } from '../helpers.js';
import { css } from '../css/index.js';

const containerConfig = {
transform:({ containerSize, ...rest }) => {
  const containerSizes = {
    full: "none",
    lg: "1172px",
    md: "972px",
    sm: "752px",
    xl: "1515px",
    xs: "536px"
  };
  return {
    maxWidth: containerSizes[containerSize || "lg"],
    mx: "auto",
    px: "s16",
    width: "100%",
    ...rest
  };
}}

export const getContainerStyle = (styles = {}) => {
  const _styles = getPatternStyles(containerConfig, styles)
  return containerConfig.transform(_styles, patternFns)
}

export const container = (styles) => css(getContainerStyle(styles))
container.raw = getContainerStyle