import createReducer from 'common/util/createReducer';

import { TrackableEvent } from 'common/modules/tracking/types';

import { TRACKING } from 'common/modules/tracking/actionTypes';

export interface State {
  contentTags?: string[];
  searchId: string;
  eventQueue?: TrackableEvent[];
}

const initialState: State = {
  searchId: '',
};

export default createReducer<State>(initialState, {
  [TRACKING.PUSH_TO_EVENT_QUEUE]: (state, action) => ({
    ...state,
    eventQueue: [...(state.eventQueue || []), action.payload],
  }),
  [TRACKING.REMOVE_CONTENT_TAGS]: ({ contentTags, ...state }) => state,
  [TRACKING.REMOVE_EVENT_QUEUE]: ({ eventQueue, ...state }) => state,
  [TRACKING.SET_CONTENT_TAGS]: (state, action) => ({ ...state, contentTags: action.payload }),
  [TRACKING.SET_SEARCH_ID]: (state, action) => ({ ...state, searchId: action.payload }),
});
