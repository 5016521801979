import { logger } from 'common/lib/Logger/logger';
import { EventGlobals } from 'common/modules/tealium/utils/tealiumEvents';

import { TrackableEvent, TrackableEventQueue } from 'common/modules/tracking/types';

/**
 * track sends an event to tealium and only on client
 * use track from useDi('core.tealium') instead if translation is needed
 *
 * @param event A data object containing the data layer variables associated with the event.
 * @param isView A boolean to switch between utag.view and utag.link
 */
export const trackEvent = (event: TrackableEvent & EventGlobals, isView = false): Promise<void> =>
  new Promise((resolve) => {
    if (typeof window !== 'undefined' && 'utag' in window && !!(window as any).utag) {
      try {
        if (isView) {
          (window as any).utag.view(event, resolve);
        } else {
          (window as any).utag.link(event, resolve);
        }
      } catch (error: unknown) {
        logger.error(error as Error, {
          extras: {
            functionName: 'track',
          },
        });
      }
    } else {
      resolve();
    }
  });

/**
 * trackQueue sends an event queue to tealium and only on client
 * use trackQueue from useDi('core.tealium') to directly use the eventQueue stored in redux.
 *
 * @param eventQueue An array containing objects of the data layer variables associated with an event.
 */
export const trackEventQueue = (eventQueue: TrackableEventQueue): Promise<void> =>
  new Promise((resolve) => {
    if (typeof window !== 'undefined' && 'utag' in window && !!(window as any).utag) {
      try {
        (window as any).utag.view(eventQueue, resolve);
      } catch (error: unknown) {
        logger.error(error as Error, {
          extras: {
            functionName: 'trackEventQueue',
          },
        });
      }
    } else {
      resolve();
    }
  });
