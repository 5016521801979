import { createContext, Reducer } from 'react';

interface TransitionState {
  isComplete: boolean;
  isLoading: boolean;
  isNavigating: boolean;
}

export const initialTransitionState = {
  isComplete: false,
  isLoading: false,
  isNavigating: false,
};

export const transitionReducer: Reducer<
  TransitionState,
  { payload: boolean; type: 'complete' | 'loading' | 'navigating' }
> = (state, action) => {
  switch (action.type) {
    case 'complete': {
      return {
        ...state,
        isComplete: action.payload,
      };
    }
    case 'loading': {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case 'navigating': {
      return {
        ...state,
        isNavigating: action.payload,
      };
    }
  }
};

const TransitionContext = createContext<TransitionState>(initialTransitionState);

export default TransitionContext;
