import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';

import { TrackingSelector, TrackingSystem } from 'common/modules/tracking/types';

export interface ITrackingCollectorService {
  trackingCollector: <S extends TrackingSelector<any>[]>(selectors: S, system: TrackingSystem) => {};
}

type TrackingObject = Record<string, unknown>;

const keysThatCanBeMerged = ['referenceIds', 'trackingParameters'];

// a bit ugly, but data tracking collects reference ids and tracking parameters into one key, to still use different
// selectors, this function merges them together.
export const merge = (oldObject: TrackingObject, newObject: TrackingObject) =>
  keysThatCanBeMerged.reduce((curr, key) => {
    if (!!oldObject[key] && !!newObject[key]) {
      return { ...curr, [key]: [...(oldObject[key] as unknown[]), ...(newObject[key] as unknown[])] };
    }
    return curr;
  }, {});

export const createTrackingCollectorService = (di: DependencyInjection): ITrackingCollectorService => {
  const trackingCollector = <S extends TrackingSelector<any>[]>(selectors: S, system: TrackingSystem) => {
    const reduxService = di.get('core.redux');
    const location = di.get('core.location').getLocation();
    const cookieService = di.get('core.cookie');
    const { t } = di.get('core.translation');

    return selectors.reduce((curr, next) => {
      const additionalValues = next({
        state: reduxService.getState(),
        system,
        location,
        cookieService,
        t,
        di,
      });
      return {
        ...curr,
        ...additionalValues,
        ...merge(curr, additionalValues),
      };
    }, {});
  };

  return { trackingCollector };
};
