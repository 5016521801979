import { Action, AnyAction } from 'redux';

import { BackLocationDescriptor, NavigationTarget } from 'common/lib/Routing/types';
import { SERVER } from 'common/lib/UniversalTools/serverActionTypes';
import { RedirectCode } from 'common/lib/UniversalTools/serverReducer';

/**
 * NOTE: this is only for the component redirects.
 * If you want to redirect inside the before/after transition handlers, use navigate()
 */
export const redirect = (locationDescriptor: NavigationTarget, code: RedirectCode = 302): AnyAction => ({
  type: SERVER.REDIRECT,
  locationDescriptor,
  code,
});

export const notFound = (): Action => ({
  type: SERVER.NOT_FOUND,
});

export const gone = (): Action => ({
  type: SERVER.GONE,
});

export const redirectWithReturnPathname = (
  redirectLocationDescriptor: NavigationTarget,
  currentLocationDescriptor: BackLocationDescriptor,
  code: RedirectCode = 302
): AnyAction => ({
  ...redirect(redirectLocationDescriptor, code),
  backLocationDescriptor: currentLocationDescriptor,
});
