import { CookieOptions } from 'express';
import { IncomingHttpHeaders } from 'http2';
import qs from 'query-string';
import setCookieParser from 'set-cookie-parser';

import { CookieKey, ICookieService } from 'common/lib/Cookies/types';
import {
  BuildHeadersParams,
  CallOptionsParams,
  FetchOptions,
  FetchResult,
  HandleResponseParams,
  Headers,
  Method,
} from 'common/lib/Fetch/types';
import { logger } from 'common/lib/Logger/logger';
import { config, routes } from 'common/util/configHandler';
import { APIEndpoint } from 'common/util/configTypes';

export const buildHeaders = ({ cookieService, customHeaders, defaultHeaders, locale }: BuildHeadersParams): Headers => {
  const cookies = typeof window === 'undefined' && cookieService.getAll?.();

  return {
    ...(defaultHeaders || {}),
    Accept: 'application/json',
    'Accept-Language': locale,
    ...(cookies ? { cookie: cookies } : {}),
    ...(customHeaders || {}),
  };
};

const allowListHeaders = ['cookie', 'origin', 'referer', 'user-agent', 'x-forwarded-for', 'x-node-request', 'x-source'];

export const filterHeaders = (headers: IncomingHttpHeaders): Headers =>
  Object.keys(headers).reduce<Headers>(
    (prev, key) => (allowListHeaders.includes(key) ? { ...prev, [key]: headers[key] as string } : prev),
    {}
  );

export const getServerOrClientSideAPI = (apiObject: APIEndpoint) => {
  if (typeof apiObject === 'string') return apiObject;

  if (__SERVER__ && apiObject.SERVER) {
    return apiObject.SERVER;
  } else {
    return apiObject.CLIENT;
  }
};

const API = getServerOrClientSideAPI(config.API);

const canHaveBody = (method: Method): boolean => !['GET', 'HEAD', 'DELETE'].includes(method);

export const buildPath = (rawPath: string, options?: FetchOptions<unknown>): string => {
  const path = !rawPath.includes(API) && !rawPath.startsWith('http') ? API + rawPath : rawPath;

  if (
    !!options?.body &&
    typeof options?.body === 'object' &&
    !canHaveBody(options.method || 'GET') &&
    Object.keys(options.body).length
  ) {
    return `${path}?${qs.stringify(options.body)}`;
  }
  return path;
};

export const stringifyError = (value: any) => {
  if (value instanceof Error) {
    const error: Record<string, any> = {};

    Object.getOwnPropertyNames(value).forEach((propName) => {
      error[propName] = value[propName as keyof Error];
    });

    return error;
  }

  return value;
};

const passCookieEnvs = ['development', 'torai'];
const getCredentials = (path: string): RequestCredentials =>
  // pass all available cookies in development client-side
  passCookieEnvs.includes(config.ENV) && path.startsWith(API) ? 'include' : 'same-origin';

export const getCallOptions = ({
  cookieService,
  defaultHeaders,
  locale,
  options,
  path,
  reduxService,
}: CallOptionsParams): RequestInit => {
  const method: Method = options.method || 'GET';

  const callOptions: RequestInit = {};

  callOptions.credentials = getCredentials(path);
  callOptions.headers = buildHeaders({
    cookieService,
    customHeaders: options.headers,
    defaultHeaders,
    locale,
  });

  callOptions.method = method;

  if (typeof options.body === 'object' && canHaveBody(method)) {
    callOptions.body = JSON.stringify(options.body);
    callOptions.headers['Content-Type'] = 'application/json';
  }

  // timeout calls server-side
  if (typeof window === 'undefined') {
    callOptions.signal = AbortSignal.timeout(
      reduxService?.getState().featureFlags.includes('fetch-timeout-reduced') ? 2000 : 4000
    );
  }

  return callOptions;
};

export const handleResponse = async <S, F>(
  response: Response,
  { authService, options, path }: HandleResponseParams
): Promise<FetchResult<S, F>> => {
  if (response.status === 401 && !options.preventNavigateToLogin) {
    authService.navigateToLogin({
      authSource: options.authSource || 'unauthorized_fetch',
      cancelTo: { route: routes.HOME },
    });
  }

  if (response.status >= 500) {
    // eslint-disable-next-line no-console
    console.warn(`ServerError (${response.status}): ${path}`);
    return {
      ok: false,
      statusCode: response.status,
      result: null,
      _serverDebugError: `API Error (${response.status}): ${path}`,
    };
  }
  if (response.status === 204) {
    return { ok: true, statusCode: 204, result: null as unknown as S };
  }

  try {
    const json = await response.json();
    return { ok: response.ok, statusCode: response.status, result: json } as FetchResult<S, F>;
  } catch (error: any) {
    if (typeof window === 'undefined') {
      // eslint-disable-next-line no-console
      console.error(`Failed to parse JSON from ${path} (${response.status})`);
    } else {
      // eslint-disable-next-line no-console
      console.error(`Failed to parse JSON from ${path} (${response.status})`, error);
    }

    logger.error(error, { tags: { path } });

    return {
      ok: false,
      statusCode: 500,
      result: null,
      _serverDebugError: { error: stringifyError(error), headers: response.headers },
    };
  }
};

export const handleSetCookieHeader = (response: Response, cookieService: ICookieService) => {
  // if backend sends set-cookie header to node we have to manually send it to client
  if (typeof window === 'undefined') {
    const setCookieHeader: string[] = response.headers.getSetCookie();

    const cookies = setCookieParser(setCookieHeader);

    cookies.forEach(({ name, value, maxAge, ...rest }) =>
      cookieService.set(
        name as CookieKey,
        value,
        { ...(maxAge ? { maxAge: maxAge * 1000 } : {}), ...rest } as CookieOptions,
        true
      )
    );
  }
};
