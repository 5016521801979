import 'theme/styles/custom.css';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RouteObject } from 'react-router';
import { matchRoutes, unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import DiContext from 'common/lib/DependencyInjection/DiContext';
import { DependencyInjection } from 'common/lib/DependencyInjection/services/services';
import { RouteLoading } from 'common/lib/Routing/types';
import { loadComponents, TransitionController } from 'common/lib/Routing/utils/transitionUtils';

import TransitionHandler from 'common/lib/Routing/components/TransitionHandler';

import { ReduxStore } from 'common/stores/global';

interface ClientCore {
  store: ReduxStore;
  browserHistory: any;
  routes: RouteLoading[];
  di: DependencyInjection;
}

const controller: TransitionController = { isAborted: false };

const runClientCore = ({ store, browserHistory, routes, di }: ClientCore) => {
  const container = document.getElementById('react-root');
  const queryClient = di.get('query.client');
  const dehydratedQueryState = window.__REACT_QUERY_STATE__;

  if (container) {
    // Prod: If the server has already provided the full HTML content: Use hydrate.
    // Dev: If the server has not provided the necessary HTML: Use render.
    if (window.__LOAD_LAZY__) {
      const root = createRoot(container);
      root.render(
        <DiContext.Provider value={di}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <HistoryRouter history={browserHistory}>
                <TransitionHandler isHydrated={false} routes={routes as RouteObject[]} />
              </HistoryRouter>
            </QueryClientProvider>
          </Provider>
        </DiContext.Provider>
      );
    } else {
      const matches = matchRoutes(routes as RouteObject[], browserHistory.location);
      loadComponents({
        controller,
        di,
        initialState: window.__INIT__,
        matches: matches || [],
      }).then(() => {
        hydrateRoot(
          container,
          <DiContext.Provider value={di}>
            <Provider store={store}>
              <QueryClientProvider client={queryClient}>
                <HydrationBoundary state={dehydratedQueryState}>
                  <HistoryRouter history={browserHistory}>
                    <TransitionHandler initialState={window.__INIT__} isHydrated routes={routes as RouteObject[]} />
                  </HistoryRouter>
                </HydrationBoundary>
              </QueryClientProvider>
            </Provider>
          </DiContext.Provider>
        );
      });
    }
  }
};

export default runClientCore;
