const defaultGlobalTranslations = [
  'global/accessibility',
  'global/alerts',
  'global/api_messages',
  'global/auth',
  'global/common',
  'global/footer',
  'global/header',
  'global/meta',
  'global/notification_center',
  'global/profile_creation',
  'global/snacks',
  'global/system',
] as const;

export default defaultGlobalTranslations;
