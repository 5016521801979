import { useCallback } from 'react';

import { consentCookies } from 'common/lib/Cookies/cookieList';
import { ConsentCategory } from 'common/lib/Cookies/types';
import { readConsentByCategory } from 'common/lib/Cookies/utils/consentUtils';
import useDi from 'common/lib/DependencyInjection/useDi';
import { logger } from 'common/lib/Logger/logger';

const useConsent = () => {
  const cookieService = useDi().get('core.cookie');
  const consentCookie = cookieService.get('CONSENTMGR');
  const gdpr = (typeof window !== 'undefined' && window.utag?.gdpr) || null;

  const getHasConsent = useCallback(() => !!consentCookie, [consentCookie]);

  const getHasCategoryConsent = useCallback(
    (category: ConsentCategory): boolean => readConsentByCategory(consentCookie || '', category),
    [consentCookie]
  );

  const setAllConsent = useCallback(() => {
    try {
      gdpr?.setPreferencesFromList(Object.keys(consentCookies) as ConsentCategory[]);
    } catch (error: unknown) {
      logger.error(error as Error, {
        extras: {
          functionName: 'setAllConsent',
        },
      });
    }
  }, [gdpr]);

  const setConsent = useCallback(
    (categories: ConsentCategory[]) => {
      try {
        gdpr && gdpr.setPreferencesFromList(categories);
      } catch (error: unknown) {
        logger.error(error as Error, {
          extras: {
            functionName: 'setConsent',
          },
        });
      }

      const cookieListToRemove = [
        ...(!categories.includes('analytics') ? [...consentCookies.analytics] : []),
        ...(!categories.includes('search') ? [...consentCookies.search] : []),
      ];
      if (cookieListToRemove.length) {
        cookieListToRemove.forEach((cookieKeyValue) => cookieService.remove(cookieKeyValue));
        window.location.reload();
      }
    },
    [gdpr, cookieService]
  );

  return {
    getHasCategoryConsent,
    getHasConsent,
    setAllConsent,
    setConsent,
  };
};

export default useConsent;
