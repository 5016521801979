import { TrackableEvent } from 'common/modules/tracking/types';

import { TRACKING } from 'common/modules/tracking/actionTypes';

export const pushToEventQueue = (event: TrackableEvent) => ({
  type: TRACKING.PUSH_TO_EVENT_QUEUE,
  payload: event,
});

export const removeContentTags = () => ({
  type: TRACKING.REMOVE_CONTENT_TAGS,
});

export const removeEventQueue = () => ({
  type: TRACKING.REMOVE_EVENT_QUEUE,
});

export const setContentTags = (contentTags: string[]) => ({
  type: TRACKING.SET_CONTENT_TAGS,
  payload: contentTags,
});

export const setSearchId = (id: string) => ({
  type: TRACKING.SET_SEARCH_ID,
  payload: id,
});
