import type { Extras, Primitive, Scope } from '@sentry/types';
import sha256 from 'sha256';

import { AppConfiguration, Tenant } from 'common/util/configTypes';

import type { ClientClassification } from 'common/modules/bot-detect/types';

type MetaData = {
  tags?: Record<string, Primitive>;
  extras?: Extras;
};

type Configuration = {
  tenant: Tenant;
  environment: string;
  origin?: 'server' | 'express' | 'client';
  sessionId?: string;
  clientClassification?: ClientClassification;
};

interface ClientLogger {
  error: (error: Error, meta?: MetaData) => void;
}

class Logger implements ClientLogger {
  error(error: Error, meta: MetaData = {}) {
    Sentry.withScope((scope: Scope) => {
      const { tags = {}, extras = {} } = meta;

      Object.keys(tags).forEach((key) => scope.setTag(key, tags[key]));
      Object.keys(extras).forEach((key) => scope.setExtra(key, extras[key]));

      Sentry.captureException(error);
    });
  }
}

class VoidLogger implements ClientLogger {
  error(error: Error, meta?: MetaData) {
    // eslint-disable-next-line no-console
    console.error(error, meta);
  }
}

export let logger: ClientLogger;

export const initLogger = (
  config: AppConfiguration,
  { clientClassification, environment, origin, sessionId, tenant }: Configuration
) => {
  if (!config.SENTRY_PUBLIC_KEY || typeof Sentry === 'undefined') {
    logger = new VoidLogger();
    return;
  }

  Sentry.init({
    ...config.SENTRY_OPTIONS,
    environment,
  });

  Sentry.configureScope((scope: Scope) => {
    scope.setTag('tenant', tenant);
    origin && scope.setTag('origin', origin);
    !!sessionId && scope.setUser({ id: sha256(sessionId) });
    !!clientClassification && scope.setTag('client.classification', clientClassification);
  });

  Sentry.forceLoad?.();

  logger = new Logger();
};
