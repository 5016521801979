import { combineReducers } from 'redux';

import matches from 'common/modules/search/reducers/matches/matchesReducer';
import searchVersion from 'common/modules/search/reducers/semantic-search/semanticSearchReducer';

import { Matches, SearchVersion } from 'common/modules/search/types';

export interface State {
  searchVersion: SearchVersion;
  matches: Matches;
}

export default combineReducers({
  searchVersion,
  matches,
});
